import React, {useEffect, useRef, useState} from 'react';
import l15 from './l15.png'
import metro from './metro_white.png';
import './App.css';
import {MercureDataInterface} from "./types/mercure";

const jwt = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6WyIqIl19fQ.HSoaYSe5VXKagdzulRXp1ZuEgpQH0Z3HuKY06sd5mD0"

function App() {
    const isMounted = useRef<boolean>(false)

    const [rangeValue, setRangeValue] = useState<number>(0)

    useEffect(() => {
        if (!isMounted.current) {
            const url = new URL('https://remotehub.omnilive.tv/.well-known/mercure');
            url.searchParams.append('authorization', jwt);
            url.searchParams.append('topic', 'average');
            const mercure = new EventSource(url)

            mercure.onmessage = ev => {
                const data: MercureDataInterface = JSON.parse(ev.data)
                setRangeValue(data.average)
                // let overlay = document.getElementById("overlay")
                // if (overlay) {
                //     overlay.style.height = `${data.average}%`
                // }
            }

            isMounted.current = true

            return () => mercure.close()
        }
    }, []);

    useEffect(() => {


        const slider = document.getElementById("omni-range") as HTMLInputElement

        if (slider) {
            let value = parseInt(slider.value)
            slider.style.background = 'linear-gradient(to right, #BB2341FF 0%, #BB2341FF ' + value + '%, #fff ' + value + '%, white 100%)'
        }


        // if (rangeValue % 10 === 0) {
            const bubbles = rangeValue / 10
            for (let i = 1; i <= 10; i++) {
                const bubble = document.getElementById(`bubble_${i}`)

                bubble?.classList.remove("bg-red", "bg-white", "bg-green")
                if (bubbles >= i) {
                    bubble?.classList.add("bg-red")
                } else if (i === 1 || i === 10) {
                    bubble?.classList.add("bg-white")
                } else {
                    bubble?.classList.add("bg-white")
                }
            }
        // }
    }, [rangeValue])

    return (
        <div className="App">
            <header className="App-header">

                <div className="bubbles-container">
                    <div className="bubble bg-red" id="bubble_1"></div>
                    <div className="bubble bg-white" id="bubble_2"></div>
                    <div className="bubble bg-white" id="bubble_3"></div>
                    <div className="bubble bg-white" id="bubble_4"></div>
                    <div className="bubble bg-white" id="bubble_5"></div>
                    <div className="bubble bg-white" id="bubble_6"></div>
                    <div className="bubble bg-white" id="bubble_7"></div>
                    <div className="bubble bg-white" id="bubble_8"></div>
                    <div className="bubble bg-white" id="bubble_9"></div>
                    <div className="bubble bg-red" id="bubble_10"></div>
                </div>

                <div className="slider-container">
                    <input type="range" min="0" max="100" value={rangeValue} className="slider" id="omni-range" readOnly={true}/>
                </div>

                <div className="text-container">
                    <p>Le premier roulage <br/>du Grand Paris Express</p>
                </div>
            </header>
        </div>
    );
}

export default App;
